import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

@inject(DialogController,JwtService,LeavemanagementService,DialogService,Router,LoginService)
export class PTOAnnouncement{
    pageSize =config.grid_PazeSize;
    searchTerm = '';
    search = '';
    showTable = false;
    FromDate = null;
    ToDate = null;
    isLoading = false;
   showEmployeeDropdown = false;
   FavoritesExist = false;
    Successmessages=false;
   sitemapId=constants.ptoannouncementsitemapid;
   filteredEmployeesSummery=[];
   

    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
    }
    active(){
        const searchButton = document.getElementById('search-button');
        searchButton.addEventListener('click', handleSearch);
    }
    async attached(){

        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        }
        const d = new Date();
        this.Year = d.getFullYear();
        const selectedYear = this.year;
        this.leavemanagementService.GetEmployeeLeaveYearsDropdown(this.empid)
            .then(response => {
                this.Years = response;
                console.log(response);
                return false;
                
            });
            
        const timeoff= {
            EmployeeID:this.empid,
            From:this.From,
            Year:d.getFullYear(),
        };
        this.isLoading = true;
        this.leavemanagementService.GetEmployeeLeaveCredits(timeoff).then(data=>
        {
            this.filteredEmployeesSummery=data;
            console.log(this.filteredEmployeesSummery);
            this.isLoading = false;
        });
       
        // this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
        // .then(data=>{
        //     this.FavoritesExist=data;
        //     console.log(data);
        // });
        // this.addToRecentItems();
    }

    exportData() {
        const data = this.filteredEmployeesSummery;
        const filename = 'PTOcredits';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            Leavetype: 'Time off type',
            Numberofdays: 'Number of days',
            Crediteddate: 'Credited date',
            Reason: 'Reason'
           
        };
        const filteredData = data.map(employee => {
            return {
                Leavetype: employee.LeaveTypeName,
                Numberofdays: employee.NoofDays,
                Crediteddate: employee.CreatedDTtext,
                Reason: employee.Reason
                
            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(employee => Object.values(employee));
        const worksheet = XLSX.utils.aoa_to_sheet([...[headerRow], ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }



    
   
    // Search() {
    //     this.showTable = true;
       
    //     const d = new Date();
    //     this.year = d.getFullYear();
    //     let selectedYear = this.year;
    //     if (this.Year) {
    //         selectedYear = this.Year.Year;
    //     }
    //         const timeoff = {
    //             EmployeeID:this.empid,
    //             Year: selectedYear
    //         };
    
    //         console.log(timeoff);
    //         this.isLoading = true;
    
    //         this.leavemanagementService.GetEmployeeLeaveCredits(timeoff).then(data => {
    //             this.filteredEmployeesSummery = data;
    //             this.isLoading = false;
    //         });
        
    // }
    
    // Reset(){
        
    //    this.selectedDepartment ="";
    //    this.searchTerm = ''; 
    //    this.selectedEmployee = [];
    //    this.selectedEmployee ="";
    //    this.attached();
    // }
   
   

    // addToFavourites(){
    //     this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
    //      {
    //         console.log(this.sitemapId);
    //         console.log(this.empid);
    //          window.location.reload();
    //      });
 
    //  }

    //  addToRecentItems(){    
    //     this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
    //      {
    //       // window.location.reload();

    //      });
    //  }
   
    
   
   
}

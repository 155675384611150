import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { Viewtimeoffrequestcomponent } from '../viewtimeoffrequest/viewtimeoffrequestcomponent';
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import {ApproveTimeOffComponent} from "../approvetimeoff/approvetimeoffcomponent";
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';

@inject(DialogController,JwtService,LeavemanagementService,ValidationControllerFactory,DialogService,Router,LoginService)
export class Addtimeoffadjustmentcomponent{
    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    showTable = false;
    FromDate = null;
    ToDate = null;
    isLoading = false;
   showEmployeeDropdown = false;
   FavoritesExist = false;
    Successmessages=false;
   sitemapId=constants.mytimeoffdetailssitemapid;
   selectedleavetype = null;
   numberoftimeadjustment = '';
   DocumentName='';
   selectedDepartment = null; 
   selectedDocType = null;
   controllerFactory;
   selectedStatus = 'ACT';

    statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending approval', value: 'ACT', checked: false },
        // { label: 'Cancelled', value: 'INA', checked: false },
        { label: 'Approved', value: 'APP', checked: false },
        { label: 'Taken', value: 'Taken', checked: false },
    ];

    constructor(controller,JwtService,LeavemanagementService,controllerFactory,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
        this.controllerFactory = controllerFactory.createForCurrentScope();

        ValidationRules
        .ensure('selectedDepartment').required().withMessage("Please select department name.")
        .ensure('numberoftimeadjustment').required().withMessage("Number of time off(s) adjustment field is required.")
        .ensure('Reason').required().withMessage("The reason field is required.")
        .ensure('selectedleavetype').required().withMessage("Please select time off type.")
        .ensure('searchTerm').required().withMessage("Please select employee name.")
        .on(this);
    }
   
    // activate(viewData){
       
    //     this.Data = viewData;
    //     console.log(this.Data);
    //     this.selectedDepartment  = viewData.DepartmentName;
    //     console.log(this.selectedDepartment);
    //     //this.LeaveApplicationIDFromSave = viewData.viewdata;
    //     // this.LeaveApplicationIDFromView = viewData.leaveId;
    //     // this.EmployeePic = viewData.PhotoPath;
    //     // this.LeaveCreditId=viewData.LeaveCreditId;
    //     // this.LeaveTypid=viewData.LeaveTypeID;
    //     // this.EmployeeID=viewData.EmployeeId;
    //     // this.Type =viewData.Type;
    //     // this.DepartmentName =viewData.DepartmentName;
    //     // this.EmployeeName =viewData.EmployeeName;
    //     // console.log( this.EmployeeName);
    //     // if(this.LeaveApplicationIDFromSave > 1){
    //     //     this.LeaveApplicationID = this.LeaveApplicationIDFromSave;
    //     // }
    //     // if(this.LeaveApplicationIDFromView > 1){
    //     //     this.LeaveApplicationID = this.LeaveApplicationIDFromView;
    //     // }
    //     // if(this.LeaveApplicationIDFromSave > 1){
    //     //     this.message = "Time off applied successfully"
    //     // }
    // }

   async attached(){
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        } 
      const documentTypes = await this.leavemanagementService.DepartmentsForDropdown();
        console.log(documentTypes);
      this.documentTypesDropdown = documentTypes;
      this.documentTypesDropdown = Object.entries(documentTypes).map(([key, value]) => ({
         value,
         key
      }));
      console.log(this.documentTypesDropdown);

    }
   
    async displayEmployeess(event) {
        this.showEmployeeDropdown = true;
        const statusVal = event.target.value;
       this.employeestatus =statusVal;
       console.log(this.employeestatus);
        
    }
    async departmentSelected() {
        console.log(this.selectedDepartment.value.DepartmentId);
        console.log(this.employeestatus);
      
            if (this.selectedDepartment.value.Name !== "Select") {
              
                this.allEmployees = await this.leavemanagementService.GetEmployeeNamesByID(this.selectedDepartment.value.DepartmentId,this.employeestatus);
                //this.allEmployees = await this.leavemanagementService.GetEmployeeNamesByDepartmentID(this.selectedDepartment.value.DepartmentId);
            } else {
                
                this.allEmployees = [];
            }
        }
        dateSort(a, b, sortOrder) {
            let date1 = new Date(a.registered);
            let date2 = new Date(b.registered);
            if (date1 === date2) {
                return 0;
            }
            if (date1 > date2) {
                return 1 * sortOrder;
            }
            return -1 * sortOrder;
        }
        nameLength(row) {
            return row.Type.length;
        }

    async handleSearch() {
        if (!this.searchTerm) {
          return;
        }
        this.filteredResults = this.allEmployees.filter(employee =>
          employee.EmployeeName.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
        this.showDropdown = true;
      }
      async handleSelection(employee) {
        //this.showTree = false;
        console.log(employee);
        this.searchTerm = employee.EmployeeName;
        this.selectedEmployee = employee;

        this.leavemanagementService.GetEmployeedetailesByEmployeeId(employee.EmployeeID)
        .then(response => {
            this.employeedetails = response;
            console.log( this.employeedetails);
            this.dateofjoin =this.employeedetails.DateofJointext;
            this.Employeeclass= this.employeedetails.EmployeeClassText;
            console.log( this.dateofjoin);
            console.log(response);
            return false;
           
        });

        this.leavemanagementService.GetLeaveTypesForDropDown(employee.EmployeeID)
        .then(response => {
            this.Types = response;
            return false;
            
        });

       

        this.EmployeeError = '';
        this.showDropdown = false; // Close the dropdown after selection


        
      }
      async leavetypeSelected() {
        console.log(this.selectedleavetype);
            if (this.selectedleavetype !== "Select") {
                const selectedTypeId = this.selectedleavetype;
                console.log(selectedTypeId);
                this.TotalTimeoffs=0;
               // this.Noofleaves = await this.leavemanagementService.GetMaxNoofLeaves(selectedTypeId,this.selectedEmployee.EmployeeID,this.TotalTimeoffs);
                this.mytimeoffsummary = await this.leavemanagementService.GetMyTimeOffSummaryByLeaveTypeId(this.selectedEmployee.EmployeeID,selectedTypeId);
                this.LeaveBalance =this.mytimeoffsummary.LeaveBalanceDays;
                this.LeaveCredit = this.mytimeoffsummary.LeaveCreditDays;
                console.log(this.LeaveBalance);

            } else {
                
                this.allEmployees = [];
            }
        }

      async  toggleCertificationFields(checked) {
        this.showCertificationFields = checked;
       
    }
    
    handleFileSelect(files) {
        const file = files[0];
        this.file = file;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        this.DocumentName = file.name;
        reader.onload = () => {
            this.file = reader.result;
        };
    }
    openFileDialog() {
        document.getElementById('attachments').click();
    }
    removeFile() {
        this.file = null;
        this.DocumentName = '';
        const fileInput = document.getElementById('attachments');
        if (fileInput) {
            fileInput.value = '';
        }
    }
   async onCerticationSelected() {
       
        console.log(this.numberoftimeadjustment);

        this.Noofleaves = await this.leavemanagementService.GetMaxNoofLeaves(this.selectedleavetype,this.selectedEmployee.EmployeeID,this.numberoftimeadjustment);
       console.log(this.Noofleaves);
      }
    yearMatcher = (a, b) => {
        return a.Year === b.year;
    };

   
    save() {
        const refreshEvent = new CustomEvent('refreshAddSkill');
        
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    //const empid = this.jwtService.getEmployeeId();
                    let data = {
                        EmployeeID: this.selectedEmployee.EmployeeID,
                        LeaveTypeId: this.selectedleavetype,
                       // NoofDays: this.Ratings,
                        Reason:this.Reason,
                        SendMailIND:this.Sendmaiind,
                        ServiceAnniversaryIND:this.Serviceanniversaryind,
                        ModifiedNoofDays:this.numberoftimeadjustment,
                        DayValue:this.numberoftimeadjustment,
                        UploadDocuments:this.file,
                        DocumentName:this.DocumentName,
                        DocumentPath:this.file,
                        LogginEmployeeID:this.empid,

                    };
                    console.log(data);

                    this.leavemanagementService.SaveCreditLeave(data)
                    .then(() => {
                        this.controller.cancel();
                        this.leavemanagementService.GetTimeOffAdjustments(empid)
                            .then(data => {
                                this.filteredEmployeesSummery = data;
                                document.dispatchEvent(refreshEvent); // Trigger the custom event
                            });
                    });
                    
                }
                else{
                    const errorMessages = document.querySelectorAll(".error-messages");
                if (errorMessages.length > 0) {
                    errorMessages[0].scrollIntoView({ behavior: "smooth" });
                }}
            });
    }


    cancel(){
        this.controller.cancel();
    }
    Reset() {
        this.attached();
    }
}

import {inject,bindingMode,bindable } from 'aurelia-framework';
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import * as XLSX from 'xlsx';
import { constants} from '../../../shared/services/constants';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import {ViewTimeOffGridComponent} from "../mytimeoffsummary/ViewTimeOffGrid/viewtimeoffgridcomponent";

    @inject(DialogController,JwtService,LeavemanagementService,DialogService,LoginService,Router)
export class MyTimeoffSummaryComponent{
    leaveViewList = [];
    isLoading = false;
    FavoritesExist = false;
    sitemapId=constants.mytimeoffsummarysitemapid;
    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
    }
    getCurrentRouteURL() {
      const currentInstruction = this.router.currentInstruction;
      if (currentInstruction) {
        const routeURL = currentInstruction.fragment;
        const sidebarListItem = document.querySelector('.sidebar ul li.mdi-calendar-clock');
        if (sidebarListItem) {
          const subActiveItems = document.querySelectorAll('.sidebar ul li.sub-active');
          subActiveItems.forEach(item => item.classList.remove('sub-active'));
          const activeLinkItems = document.querySelectorAll('.sidebar ul li.active-link');
          activeLinkItems.forEach(item => item.classList.remove('active-link'));
          sidebarListItem.classList.add('sub-active');
        }
        const sidebarLink = document.querySelector('.sidebar ul li.mdi-calendar-clock .sidebar-sub .my-leave-summary a');
        if (sidebarLink) {
          sidebarLink.classList.add('active-link');
        }
        return routeURL;
      }
      return '';
    }

    attached(){
        this.getCurrentRouteURL();
        this.isLoading = true;
        this.leavemanagementService.GetMyTimeOffSummary(this.empid).then(data=>
        {
            this.leaveViewList=data;
            console.log(this.leaveViewList)
            this.isLoading = false;
        });
        this.leavemanagementService.GetPersonFavoriteItems(this.empid,149)
        .then(data=>{
            this.FavoritesExist=data;
        });
        this.addToRecentItems();
    }
    calculateColumnTotal(columnName) {
            const total = this.leaveViewList.reduce((acc, leave) => {
                const rowValue = leave[columnName] || '';
                const rowSum = parseFloat(rowValue || 0);
                return acc + rowSum;
            }, 0);
            return total.toFixed(2); // Format the total to display with two decimal places
        }
        openPopup(value,leavedata) {
            const modelData = {
                headerText: value,
                leaveData: leavedata
            };
            this.dialogService.open({viewModel: ViewTimeOffGridComponent, model: modelData});
        }
    exportData() {
        const data = this.leaveViewList;
        const filename = 'Mytimeoffsummary';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            Timeofftype: 'Time off type',
            Timeoffcredits: 'Time off credits',
            Timeoffstaken: 'Time offs taken',
            Timeoffsscheduled: 'Time offs scheduled',
            Timeoffsbalance: 'Time offs balance',
            Pendingapproval: 'Pending approval',
            Pendinglossofpay:'Pending loss of pay',
            Approvedlossofpay: 'Approved loss of pay',
            Cancelledtimeoff:'Cancelled time off'
            
        };
        const filteredData = data.map(leave => {
            return {
                Timeofftype: leave.LeaveTypeName,
                Timeoffcredits: leave.LeaveCreditDays,
                Timeoffstaken: leave.LeaveAppliedDays,
                Timeoffsscheduled: leave.ScheduleBalance,
                Timeoffsbalance: leave.LeaveBalanceDays,
                Pendingapproval: leave.Pendingforapproval,
                Pendinglossofpay:leave.Nooflopspending,
                Approvedlossofpay:leave.ApprovedLossofPay,
                Cancelledtimeoff:leave.CanceledLeaves,
            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(leave => Object.values(leave));
        const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    addToFavourites(){
         this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
          {
              window.location.reload();
          });
      }
      addToRecentItems(){    
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {

         });
     }

}

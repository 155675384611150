import { inject, bindingMode, bindable } from "aurelia-framework";
import flatpickr from "flatpickr";
import { JwtService } from "../../../shared/services/jwtservice";
import { DialogController } from "aurelia-dialog";
import { DialogService } from "aurelia-dialog";
import { LeavemanagementService } from "../../../shared/services/leavemanagementservice";
import { Employeetimeoffadjustmentcomponent } from "../employeetimeoffadjustmentdetailes/employeetimeoffadjustmentcomponent";
import * as XLSX from "xlsx";
import { config } from "../../../shared/services/config";
import { ApproveTimeOffComponent } from "../approvetimeoff/approvetimeoffcomponent";
import { constants } from "../../../shared/services/constants";
import Scrollbar from "smooth-scrollbar";
import { LoginService } from "../../../shared/services/loginservice";
import { Router } from "aurelia-router";
import { Addtimeoffadjustmentcomponent } from "../timeoffadjustments/addtimeoffadjustmentcomponent";
import { Viewtimeoffadjustmentcomponent } from "../viewtimeoffadjustment/viewtimeoffadjustmentcomponent";
import { Edittimeoffadjustmentcomponent } from "../timeoffadjustments/edittimeoffadjustmentcomponent";

@inject(
  DialogController,
  JwtService,
  LeavemanagementService,
  DialogService,
  Router,
  LoginService
)
export class MyTimeoffDetailsComponent {
  pageSize = config.grid_PazeSize;
  searchTerm = "";
  search = "";
  showTable = false;
  FromDate = null;
  ToDate = null;
  isLoading = false;
  showEmployeeDropdown = false;
  FavoritesExist = false;
  Successmessages = false;
  sitemapId = constants.employeependingtimeoffadjustments;
  selectedclassID = null;
  selectedDepartment = null;
  $timeoffdetailsData = [];

  statusOptions = [
    { label: "All", value: "All", checked: false },
    { label: "Pending with me", value: "PEAM", checked: true },
    { label: "Pending approval", value: "PEA", checked: false },
    // { label: 'Apporved', value: 'ACT', checked: false },
  ];

  constructor(
    controller,
    JwtService,
    LeavemanagementService,
    DialogService,
    loginservice,
    router
  ) {
    this.controller = controller;
    this.jwtService = JwtService;
    this.dialogService = DialogService;
    this.empid = this.jwtService.getEmployeeId();
    this.leavemanagementService = LeavemanagementService;
    this.router = router;
    this.loginservice = loginservice;
  }
  active() {
    const searchButton = document.getElementById("search-button");
    searchButton.addEventListener("click", handleSearch);
  }
  async attached() {
    this.leavemanagementService.GetYearsDropdown().then((data) => {
      this.years = data;
    });
    const d = new Date();
    this.year = d.getFullYear();
    const selectedStatusList = this.statusOptions
      .filter((status) => status.checked)
      .map((status) => status.value);
    console.log(this.statusOptions);

    const timeoff = {
      LoggedInEmployeeId: this.empid,
      ApplicableYear: d.getFullYear(),
      StatusList: selectedStatusList,
    };
    this.leavemanagementService.DepartmentsForDropdown().then((response) => {
      this.departments = response;
      return false;
    });
    this.allEmployees =
      await this.leavemanagementService.GetEmployeemasterDetails();

    this.leavemanagementService
      .GetPersonFavoriteItems(this.empid, this.sitemapId)
      .then((data) => {
        this.FavoritesExist = data;
      });

    this.addToRecentItems();
    const windowWidth = window.innerWidth;
    if (windowWidth <= 1280) {
      Scrollbar.init(document.querySelector(".disable-desktop-scroll"));
    }

    // const documentTypes =
    //   await this.leavemanagementService.DepartmentsForDropdown();
    // console.log(documentTypes);
    // this.documentTypesDropdown = documentTypes;
    // this.documentTypesDropdown = Object.entries(documentTypes).map(
    //   ([key, value]) => ({
    //     value,
    //     key,
    //   })
    // );

    // const employeeclassTypes =
    //   await this.leavemanagementService.GetEmployeeClassDropdown();
    // this.employeeclassTypesDropdown = employeeclassTypes;
    // this.employeeclassTypesDropdown = Object.entries(employeeclassTypes).map(
    //   ([key, value]) => ({
    //     value,
    //     key,
    //   })
    // );

    this.isLoading = true;
    this.leavemanagementService.GetTimeOffAdjustments(timeoff).then((data) => {
      console.log("HII");
      this.filteredEmployeesSummery = data.AllLeaveTypes;
      this.isleaveadmin = data.IsLeaveAdmin;
      this.ishr = data.IsHR;
      this.isLoading = false;
      console.log(this.isleaveadmin,this.ishr);
      console.log(this.filteredEmployeesSummery);
    });

    this.leavemanagementService
      .GetPersonFavoriteItems(this.empid, this.sitemapId)
      .then((data) => {
        this.FavoritesExist = data;
      });
    // this.addToRecentItems();
  }
  addTimeofaddjustment(user) {
    this.dialogService.open({
      viewModel: Addtimeoffadjustmentcomponent,
      model: user,
    });
  }

  openPopup(employee) {
    this.dialogService.open({
      viewModel: Employeetimeoffadjustmentcomponent,
      model: employee,
    });
  }
  async departmentSelected() {
    if (this.selectedDepartment !== "Select") {
      this.allEmployees =
        await this.leavemanagementService.GetEmployeeNamesByDepartmentID(
          this.selectedDepartment
        );
    } else {
      this.allEmployees = [];
    }
  }
  async handleSearch() {
    if (!this.searchTerm) {
      this.filteredResults = [];
      return;
    }
    this.filteredResults = this.allEmployees.filter((employee) =>
      employee.EmployeeName.toLowerCase().includes(
        this.searchTerm.toLowerCase()
      )
    );
    this.showDropdown = true;
  }
  async handleSelection(employee) {
    //this.showTree = false;
    this.searchTerm = employee.EmployeeName;
    this.selectedEmployee = employee;
    this.EmployeeError = "";
    this.showDropdown = false; // Close the dropdown after selection
  }

  filterClick() {
    event.preventDefault();
    const clickedDiv = event.target.parentNode;
    clickedDiv.classList.toggle("filter-open");
    // Remove 'filter-open' class from other divs
    const divs = document.querySelectorAll(".filter-open");
    divs.forEach((div) => {
      if (div !== clickedDiv) {
        div.classList.remove("filter-open");
      }
    });
    const handleOutsideClick = (event) => {
      if (!clickedDiv.contains(event.target)) {
        divs.forEach((div) => {
          div.classList.remove("filter-open");
        });
        document.removeEventListener("click", handleOutsideClick);
      }
    };

    if (clickedDiv.classList.contains("filter-open")) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
  }

  selectOption(option) {
    this.selectedOption = option;
    this.isDropdownOpen = false;
  }
  selectAllChanged(event) {
    const isChecked = this.statusOptions[0].checked;
    this.statusOptions.forEach((status, index) => {
      if (index !== 0) {
        status.checked = isChecked;
      }
    });
  }

  handleCheckboxChange(event, status) {
    if (status.value === "All") {
      this.statusOptions.forEach((statusOption, index) => {
        if (index !== 0) {
          statusOption.checked = false;
        }
      });
    } else {
      const allChecked = this.statusOptions
        .slice(1)
        .every((statusOption) => statusOption.checked);
      this.statusOptions[0].checked = allChecked;
    }

    //status.checked = event.target.checked;
  }

  nameLength(row) {
    return row.Type.length;
  }

  addToFavourites() {
    this.leavemanagementService
      .AddToFavourites(this.empid, this.sitemapId)
      .then((data) => {
        window.location.reload();
      });
  }

  addToRecentItems() {
    this.leavemanagementService
      .AddToRecentItems(this.empid, this.sitemapId)
      .then((data) => {
        // window.location.reload();
      });
  }

  yearMatcher = (a, b) => {
    return a.Year === b.year;
  };

  Search() {
    const filterOpenElements = document.querySelectorAll(".filter-open");
    filterOpenElements.forEach((element) =>
      element.classList.remove("filter-open")
    );
    // const selectedStatusLists = this.selectedStatuses.map(status => status.value);
    const selectedStatusList = this.statusOptions
      .filter((status) => status.checked)
      .map((status) => status.value);

    const selectedRadio = document.querySelector(
      'input[name="employees"]:checked'
    );
    this.selectedValue = selectedRadio ? selectedRadio.value : "";
    this.showTable = true;
    this.errors = null;
    const d = new Date();
    this.year = d.getFullYear();
    
    let selectedYear = this.year;
    if (this.Year) {
      selectedYear = this.Year.Year;
    }

    console.log(this.empid);
    // this.controllerFactory.validate()
    //     .then(result => {
    // if (result.valid) {
    if (this.selectedEmployee && this.selectedEmployee.EmployeeID) {
      const timeoff = {
        DepartmentID: this.selectedDepartment,
        EmployeeId: this.selectedEmployee.EmployeeID,
        ApplicableYear: selectedYear,
        LoggedInEmployeeId: this.empid,
        StatusList: selectedStatusList.includes("All")
          ? ["All"]
          : selectedStatusList.filter((status) => status !== "All"),
      };
      this.isLoading = true;
      this.leavemanagementService
        .GetTimeOffAdjustments(timeoff)
        .then((data) => {
          this.filteredEmployeesSummery = data.AllLeaveTypes;
          this.isLoading = false;
        });
    } else {
      const timeoff = {
        DepartmentID: this.selectedDepartment,
        LoggedInEmployeeId: this.empid,
        ApplicableYear: selectedYear,
        StatusList: selectedStatusList.includes("All")
          ? ["All"]
          : selectedStatusList.filter((status) => status !== "All"),
      };
      this.isLoading = true;
      this.leavemanagementService
        .GetTimeOffAdjustments(timeoff)
        .then((data) => {
          this.filteredEmployeesSummery = data.AllLeaveTypes;
          this.isLoading = false;
        });
    }
    // }
    // });
  }

  // calculateDateDifference() {
  //     const filterOpenElements = document.querySelectorAll('.filter-open');
  //     filterOpenElements.forEach(element => element.classList.remove('filter-open'));
  //     // const selectedStatusLists = this.selectedStatuses.map(status => status.value);
  //     const selectedStatusList = this.statusOptions
  //         .filter(status => status.checked)
  //         .map(status => status.value);

  //     const selectedRadio = document.querySelector('input[name="employees"]:checked');
  //     this.selectedValue = selectedRadio ? selectedRadio.value : '';
  //     if(this.selectedclassID == null )
  //     {
  //         this.EmployeeClassificationID = 0;
  //     }
  //     else
  //     {
  //         this.EmployeeClassificationID = this.selectedclassID.value.EmployeeClassificationID ;
  //     }

  //     if(this.selectedDepartment == null )
  //     {
  //         this.DepartmentId = 0;
  //     }
  //     else
  //     {
  //         this.DepartmentId =this.selectedDepartment.value.DepartmentId ;
  //     }
  //     const timeoff = {
  //         // Fromdate: finalFromDate,
  //         // Todate: finalToDate,

  //         EmployeeClassificationID: this.EmployeeClassificationID,
  //          DepartmentId : this.DepartmentId ,
  //         EmployeeStatus: this.selectedValue,
  //         StatusList: selectedStatusList.includes('All') ? ['All'] : selectedStatusList.filter(status => status !== 'All'),
  //         // DepartmentID: this.department,
  //         // EmployeeClassID: this.employeeclass,
  //         // LoggedInEmployeeId : this.empid,
  //         EmployeeId:this.empid,
  //     };
  //     this.isLoading = true;

  //     this.leavemanagementService.GetTimeOffAdjustments(timeoff).then(data=>
  //         {
  //             console.log("HII");
  //             this.filteredEmployeesSummery=data.AllLeaveTypes;
  //             this.isLoading = false;
  //             console.log(this.filteredEmployeesSummery);
  //         });

  // }
  toggleDropdown($event, employee) {
    //console.log(employee);
    const isDropdownOpen = employee.showDropdown;
    this.$timeoffdetailsData.forEach((item) => {
      item.showDropdown = false;
      item.timeoffshowDropdown = false;
    });
    if (!isDropdownOpen) {
      employee.showDropdown = true;
      employee.timeoffshowDropdown = false;
      document.addEventListener("click", this.closeDropdown);
    }
    $event.stopPropagation();
  }
  closeDropdown = () => {
    this.$timeoffdetailsData.forEach((item) => {
      item.showDropdown = false;
      item.timeoffshowDropdown = false;
    });
    document.removeEventListener("click", this.closeDropdown);
  };
  viewToggleDropdown($event, employee) {
    //console.log(employee);
    const timeoffisDropdownOpen = employee.timeoffshowDropdown;
    this.$timeoffdetailsData.forEach((item) => {
      item.timeoffshowDropdown = false;
      item.showDropdown = false;
    });
    if (!timeoffisDropdownOpen) {
      employee.timeoffshowDropdown = true;
      employee.showDropdown = false;
      document.addEventListener("click", this.viewcloseDropdown);
    }
    $event.stopPropagation();
  }
  viewcloseDropdown = () => {
    this.$timeoffdetailsData.forEach((item) => {
      item.timeoffshowDropdown = false;
      item.showDropdown = false;
    });
    document.removeEventListener("click", this.timeoffcloseDropdown);
  };
  closeEmployeetoggle() {
    this.filteredEmployees.forEach((item) => {
      item.showEmployeeDropdown = false;
    });
    document.removeEventListener("click", this.closeEmployeetoggle);
  }

  showDialog(
    LeaveCreditId,
    leaveAppId,
    EmployeeId,
    EmployeeName,
    DepartmentName,
    Type
  ) {
    const viewdata = {
      LeaveCreditId: LeaveCreditId,
      LeaveTypeID: leaveAppId,
      EmployeeId: EmployeeId,
      EmployeeName: EmployeeName,
      DepartmentName: DepartmentName,
      Type: Type,
    };
    this.dialogService.open({
      viewModel: Viewtimeoffadjustmentcomponent,
      model: viewdata,
    });
  }

  ApproveShowDialog(user) {
    // const approveData = {
    //     selectedRecordId : leaveAppId,
    //     StatusCode : StatusCode,
    //     EmployeeId:EmployeeId,
    //     actions:actions,

    // };
    this.dialogService.open({
      viewModel: Edittimeoffadjustmentcomponent,
      model: user,
    });
  }
  cancel() {
    this.controller.cancel();
  }
  resetPage() {
    console.log(this.selectedDepartment);
    if (
      this.selectedDepartment &&
      typeof this.selectedDepartment.value === "function"
    ) {
      // If value is a function (possibly a getter), invoke it to get the actual value
      this.selectedDepartment = { value: { Name: "" } };
    } else if (this.selectedDepartment && this.selectedDepartment.value) {
      // If value is an object, directly set its properties
      this.selectedDepartment.value.Name = "";
    }
    // this.selectedDepartment.value.Name ="";
    if (this.selectedclassID && this.selectedclassID.value) {
      this.selectedclassID.value.ClassificationName = "";
    }
    //this.selectedclassID = "";
    this.selectedDepartment = "";
    this.searchTerm = "";
    this.selectedEmployee = [];
    this.selectedEmployee = "";
    this.attached();
  }

  removeFilter(Allstatus, status) {
    if (status.label === "All") {
      this.statusOptions.forEach((option) => {
        option.checked = false;
      });
    } else {
      status.checked = false;
    }
  }

  resetStatusDropdown() {
    this.statusOptions.forEach((status) => {
      // if (index === 1 || index === 2) {
      //     status.checked = true; // Select 'Pending approval' and 'Pending with me'
      // } else {
      //     status.checked = false; // Deselect other statuses
      // }
      if (status.value === "PEAM") {
        status.checked = true; // Select 'Pending with me'
      } else {
        status.checked = false; // Deselect other statuses
      }
    });

    // Update the selectedStatusList if needed
    this.selectedStatusList = this.statusOptions.filter(
      (status) => status.checked
    );
  }

  exportData() {
    const data = this.filteredEmployeesSummery;
    const filename = 'timeoffadjustments';
    this.exportToExcel(data, filename);
}
exportToExcel(data, filename) {
    const headers = {
      Employeecode: 'Employee code',
      Employeename: 'Employee name',
      Employeeclass: 'Employee class',
      Timeofftype: 'Time off type',
      Adjustedtimeoff: 'Adjusted time off',
      Balancetimeoffs: 'Balance time offs',
      Pendingapproval:'Pending approval',
      Status: 'Status'
       // ApprovedlossofPay:'Approved loss of Pay'
        
    };
    const filteredData = data.map(timeoff => {
        return {
          Employeecode:timeoff.EmployeeCode,
          Employeename:timeoff.EmployeeName,
          Employeeclass:timeoff.EmployeeClass,
          Timeofftype:timeoff.Type,
          Adjustedtimeoff:timeoff.Leavecredits,
          Balancetimeoffs:timeoff.BalancedLeaves,
          Pendingapproval:timeoff.PendingApproval,
          Status: timeoff.statusText,
           // ApprovedlossofPay:leave.ApprovedLossOffPay
        };
    });
    const headerRow = Object.values(headers);
    const dataRows = filteredData.map(leave => Object.values(leave));
    const worksheet = XLSX.utils.aoa_to_sheet([headerRow, ...dataRows]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    XLSX.writeFile(workbook, `${filename}.xlsx`);
}
}

import {inject,bindingMode,bindable } from 'aurelia-framework';
import flatpickr from "flatpickr";
import { JwtService } from '../../../shared/services/jwtservice';
import { DialogController } from 'aurelia-dialog';
import {DialogService} from 'aurelia-dialog';
import {LeavemanagementService} from "../../../shared/services/leavemanagementservice";
import { Viewtimeoffrequestcomponent } from '../viewtimeoffrequest/viewtimeoffrequestcomponent';
import * as XLSX from 'xlsx';
import {config} from '../../../shared/services/config';
import {ApproveTimeOffComponent} from "../approvetimeoff/approvetimeoffcomponent";
import { constants } from '../../../shared/services/constants';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../shared/services/loginservice";
import {Router} from 'aurelia-router';
import { ViewEmpTimeOffRequestPopupComponent} from '../viewemptimeoffrequestpopup/viewemptimeoffrequestpopupcomponent';

@inject(DialogController,JwtService,LeavemanagementService,DialogService,Router,LoginService)
export class MyTimeoffDetailsComponent{
    pageSize = config.grid_PazeSize;
    searchTerm = '';
    search = '';
    showTable = false;
    FromDate = null;
    ToDate = null;
    isLoading = false;
   showEmployeeDropdown = false;
   FavoritesExist = false;
    Successmessages=false;
   sitemapId=constants.mytimeoffdetailssitemapid;
    statusOptions = [
        { label: 'All', value: 'All', checked: false },
        { label: 'Pending approval', value: 'ACT', checked: false },
        { label: 'Cancelled', value: 'INA', checked: false },
        { label: 'Approved', value: 'APP', checked: false },
        { label: 'Taken', value: 'Taken', checked: false },
    ];

    constructor(controller,JwtService,LeavemanagementService,DialogService,loginservice,router){
        this.controller = controller;
        this.jwtService= JwtService;
        this.dialogService = DialogService;
        this.empid = this.jwtService.getEmployeeId();
        this.leavemanagementService = LeavemanagementService;
        this.router = router;
        this.loginservice = loginservice;
    }
    active(){
        const searchButton = document.getElementById('search-button');
        searchButton.addEventListener('click', handleSearch);
    }
    attached(){
        const windowWidth = window.innerWidth;
        if (windowWidth <= 1280) {
            Scrollbar.init(document.querySelector('.disable-desktop-scroll'));
        }
        function formatDateWithLeadingZero(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
            const year = date.getFullYear();
            const formattedDay = day.toString().padStart(2, '0');
            const formattedMonth = month.toString().padStart(2, '0');
            return `${formattedDay}-${formattedMonth}-${year}`;
          }
          
          const fromDate = new Date();
          fromDate.setMonth(fromDate.getMonth() - 4);
          
          this.FDate = formatDateWithLeadingZero(fromDate);
          this.From = this.FDate;

          const toDate = new Date();
          toDate.setMonth(toDate.getMonth() +6);
          
          this.TDate = formatDateWithLeadingZero(toDate);
          this.To = this.TDate;
        flatpickr(this.datePicker, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: fromDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.fromDate = dateStr;
            }
        });
        this.calendarIcon.addEventListener('click', () => {
            this.datePicker._flatpickr.open();
        });
        flatpickr(this.datePickerss, {
            closeOnSelect: true,
            dateFormat: 'd-m-Y',
            minDate: null,
            maxDate: null,
            defaultDate: toDate,
            enableTime: false,
            enableSeconds: false,
            time_24hr: false,
            minuteIncrement: 1,
            hourIncrement: 1,
            timeFormat: 'h:i K',
            clickOpens:false,
            onClose: function (selectedDates, dateStr, instance) {
                this.toDate = dateStr;
            }
        });
        this.calendarIconEnd.addEventListener('click', () => {
            this.datePickerss._flatpickr.open();
        });
        const timeoff= {
            EmployeeId:this.empid,
            From:this.From,
            To:this.To
        };
        this.isLoading = true;
        this.leavemanagementService.GetTimeOffSummary(timeoff).then(data=>
        {
            this.filteredEmployeesSummery=data.List;
            this.isLoading = false;
        });

        this.leavemanagementService.GetPersonFavoriteItems(this.empid,this.sitemapId)
        .then(data=>{
            this.FavoritesExist=data;
        });
        this.addToRecentItems();
    }
    dateSort(a, b, sortOrder) {
        let date1 = new Date(a.registered);
        let date2 = new Date(b.registered);
        if (date1 === date2) {
            return 0;
        }
        if (date1 > date2) {
            return 1 * sortOrder;
        }
        return -1 * sortOrder;
    }
    nameLength(row) {
        return row.Type.length;
    }
    popupViewTimeOffRequest(leaveAppId,PhotoPath){
        const approveData = {
            selectedRecordId : leaveAppId,
            PhotoPath:PhotoPath,
        };
        this.dialogService.open({viewModel: ViewEmpTimeOffRequestPopupComponent, model:approveData})
    }
    handleCheckboxChange(event, status) {
        // Update the checked status of the clicked option
        status.checked = event.target.checked;

        if (status.label !== 'All') {
            // Check if any checkbox other than 'All' is unchecked
            const anyUnchecked = this.statusOptions.slice(1).some(option => !option.checked);

            // If any checkbox is unchecked, uncheck the 'All' checkbox
            this.statusOptions[0].checked = !anyUnchecked;
        } else {
            // If 'All' is selected, update all other options accordingly
            this.statusOptions.forEach((option) => (option.checked = status.checked));
        }

        // Filter data based on the selected options
        this.filterData();
    }
    filterData() {
        const timeoff = {
            EmployeeId: this.empid,
            From: this.From,
            To: this.To
        };

        this.leavemanagementService.GetTimeOffSummary(timeoff).then(data => {
            this.originalEmployeesSummary = data.List;

            if (!this.originalEmployeesSummary) {
                this.filteredEmployeesSummery = []; // Handle the case where data is not available
                return;
            }
            const selectedStatuses = this.statusOptions.slice(1) // Exclude 'All' checkbox
                .filter(option => option.checked)
                .map(option => option.label);

            if (selectedStatuses.length === 0) {
                // If no checkboxes are selected (excluding 'All'), show all data
                this.filteredEmployeesSummery = this.originalEmployeesSummary;
            } else {
                // Filter data based on selected checkboxes
                this.filteredEmployeesSummery = this.originalEmployeesSummary.filter((employee) => {
                    return selectedStatuses.some(status => employee.Status.includes(status));
                });
            }
        });
    }
    Search(){
        this.showTable = true;
        const timeoff= {
          EmployeeId:this.empid,
          Fromdate:this.From,
          Todate:this.To
        };
        this.isLoading = true;
        this.leavemanagementService.GetTimeOffSummary(timeoff).then(data=>
        {
            this.filteredEmployeesSummery=data.List;
            this.isLoading = false;
        });
    }
    Reset(){
        this.attached();
    }
    exportData() {
        const data = this.filteredEmployeesSummery;
        const filename = 'Mytimeoffdetails';
        this.exportToExcel(data, filename);
    }
    exportToExcel(data, filename) {
        const headers = {
            Type: 'Type',
            From: 'From',
            To: 'To',
            Amount: 'Amount (in days)',
            AppliedDate: 'Applied date',
            Reason: 'Reason',
            Status: 'Status'
        };
        const filteredData = data.map(employee => {
            return {
                Type: employee.Type,
                From: employee.From,
                To: employee.To,
                Amount: employee.Amount,
                AppliedDate: employee.AppliedDate,
                Reason: employee.Reason,
                Status: employee.Status
            };
        });
        const headerRow = Object.values(headers);
        const dataRows = filteredData.map(employee => Object.values(employee));
        const worksheet = XLSX.utils.aoa_to_sheet([...[headerRow], ...dataRows]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
    
    addToFavourites(){
       this.leavemanagementService.AddToFavourites(this.empid,this.sitemapId).then(data=>
        {
            window.location.reload();
        });

    }

    addToRecentItems(){    
        this.leavemanagementService.AddToRecentItems(this.empid,this.sitemapId).then(data=>
         {
          // window.location.reload();

         });
     }

    filterClick() {
        event.preventDefault();
        const clickedDiv = event.target.parentNode;
        clickedDiv.classList.toggle('filter-open');
        const divs = document.querySelectorAll('.filter-open');
        divs.forEach((div) => {
            if (div !== clickedDiv) {
                div.classList.remove('filter-open');
            }
        });
        const handleOutsideClick = (event) => {
            if (!clickedDiv.contains(event.target)) {
                divs.forEach((div) => {
                    div.classList.remove('filter-open');
                });
                document.removeEventListener('click', handleOutsideClick);
            }
        };
        if (clickedDiv.classList.contains('filter-open')) {
            document.addEventListener('click', handleOutsideClick);
        } else {
            document.removeEventListener('click', handleOutsideClick);
        }
    }
    showDialog(leaveAppId) {
        this.dialogService.open({viewModel: ViewEmpTimeOffRequestPopupComponent, model: leaveAppId});
    }
    ApproveShowDialog(leaveAppId,StatusCode,EmployeeId, actions,PhotoPath) {
        const approveData = {
            selectedRecordId : leaveAppId,
            StatusCode : StatusCode,
            EmployeeId:EmployeeId,
            actions:actions,
            PhotoPath:PhotoPath,
        };
        this.dialogService.open({viewModel: ApproveTimeOffComponent, model: approveData})
            .whenClosed((feedback) => {
                if(!feedback.wasCancelled){
                    const timeoff= {
                        EmployeeId:this.empid,
                        From:this.From,
                        To:this.To
                    };
                    this.isLoading = true;
                    this.leavemanagementService.GetTimeOffSummary(timeoff).then(data=>
                    {
                        this.filteredEmployeesSummery=data.List;
                        this.isLoading = false;
                        this.Successmessages = true;
                        setTimeout(()=>{this.Successmessages=null},5000);
                    });
                }
            });
    }
}
